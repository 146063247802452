/**
 * active experiments in posthog. There are two ways to use feature flags
 * - variant key - dynamically render different content based on the value
 * - payload - show value set in posthog. Allow for experiment updates without a deploy
 *
 * Note: the result do not include 'control', and we should never explicitly check for if flag == 'control'.
 * We should always fall back to original behavior for control.
 *
 * Note: `advanced_disable_feature_flags` is only set as `true` when there are active experiments. It checks for the length of keys in activeExperiments
 * That value is set to false to reduce feature flag usage in posthog
 *
 * @example
 * "experiment-key": {
 *   variants: ["variant1", "variant2"],
 *   payload: "string returned";
 * };
 */
export const activeExperiments = {
  "landing-page-cta": {
    variants: ["large-button", "no-cc-text", "large-button-with-no-cc-text"],
    payload: undefined,
  },
  "pricing-page-toggle": {
    variants: ["annual-discount"],
    payload: undefined,
  },
  "create-page-upsell": {
    variants: ["upsell-modal"],
    payload: undefined,
  },
  "social-proof-ai-image-generator": {
    variants: ["test"],
    payload: undefined,
  },
  "social-proof-ai-headshot-generator": {
    variants: ["test"],
    payload: undefined,
  },
  "social-proof-animation": {
    variants: ["test"],
    payload: undefined,
  },
  "social-proof-face-swap": {
    variants: ["test"],
    payload: undefined,
  },
  "social-proof-image-to-video": {
    variants: ["test"],
    payload: undefined,
  },
  "social-proof-lip-sync": {
    variants: ["test"],
    payload: undefined,
  },
  "social-proof-text-to-video": {
    variants: ["test"],
    payload: undefined,
  },
  "social-proof-video-to-video": {
    variants: ["test"],
    payload: undefined,
  },
} as const;

/**
 * a type representation of activeExperiments. This is used as the types in the getFlag functions
 */
export type Experiments = {
  [K in keyof typeof activeExperiments]: {
    variants: Exclude<(typeof activeExperiments)[K]["variants"][number], "control">;
    payload: (typeof activeExperiments)[K]["payload"];
  };
};
